import {loadingController} from "@ionic/vue";
import axios from "@/libs/axios";
import { useRoute } from "vue-router"

export default function invoiceFunctions() {

    const route = useRoute()
    const prepareInvoiceDownload = async () => {
        const loader = await loadingController.create({message: 'Please wait'})
        await loader.present()
        return axios.get('/api/advisor/invoice/'+route.params.id+'/download/prepare')
            .then( (resp) => {
                if(resp.data.error){
                    console.log('Lead details error: '+resp.data.error);
                }
                else{
                    window.open(process.env.VUE_APP_API_ENDPOINT+'api/advisor/invoice/'+route.params.id+'/'+resp.data.data.download_code+'/download','_blank')
                }
            })
            .catch( err => {
                console.log('Lead details ajax: '+err)
            })
            .then(() => loader.dismiss())
    }

    return {
        prepareInvoiceDownload,
    }
}
