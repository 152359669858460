<template>
  <p class="nk-text-muted text-center">Het factuubedrag zal binnen enkele dagen van nw rekening worden afgeschreven.</p>
  <hr class="bg-light"/>
  <div class="font-weight-bold nk-text-muted">Factuur vrijgesteld van BTW</div>
  <p class="nk-text-muted">De verrichte prestaties zijn als vrijgesteld van btw zoals bedoeld in art. 11 lid 1 onderdeel de Wet OB.</p>
</template>

<script>
export default {
name: "InvoiceFooter"
}
</script>

<style scoped>

</style>
