<template>
  <img :src="require('@/assets/images/logos/logo.png')" alt="Logo" class="img-fluid"/>
  <row class="mt-4 justify-content-between">
    <column default="auto">
      <b class="d-block">Webstrategen</b>
      <div class="nk-text-muted">Bijsterhuizen 2414<br/>
        6604 LL, Wijchen<br/>
        06-36255807<br/>
        info@hypotheek.io
      </div>
    </column>
    <column default="auto" lg="4" xl="3">
      <b class="d-block">{{ $t('Company details') }}</b>
      <div>
        <span>BTW: </span>
        <span class="nk-text-muted">NL002383132B40</span>
      </div>
      <div>
        <span>KVK: </span>
        <span class="nk-text-muted">70488584</span>
      </div>
      <div>
        <span>IBAN: </span>
        <span class="nk-text-muted">NL94 INGB 0007 9658 11</span>
      </div>
      <div>
        <span>BIC: </span>
        <span class="nk-text-muted">INGBNL2A</span>
      </div>
    </column>
  </row>
  <hr class="bg-light"/>
</template>

<script>
import {Column, Row} from "@core/layouts";
export default {
name: "InvoiceHead",
  components: {Column, Row}
}
</script>
