<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-content>
            <row class="g-gs">
              <column md="12" lg="9" xl="10">
                <card>
                  <invoice-head>

                  </invoice-head>
                  <div class="my-2 font-weight-bold nk-text-muted">{{ $t('Invoice To') }}:</div>
                  <row class="justify-content-between" v-if="advisorData.first_name.length > 0">
                    <column default="auto">
                      <b class="d-block nk-text-muted">{{ advisorData.company }}</b>
                      <div class="nk-text-muted">
                        {{ advisorData.first_name +' '+advisorData.last_name }}<br/>
                        {{ advisorData.address }}<br/>
                        {{ advisorData.post_code }}
                      </div>
                    </column>
                    <column default="auto" lg="4" xl="3">
                      <div style="color: #58388d;font-size: 1.1rem;font-weight: 600;">Invoice #{{ invoiceData.invoice_id }}</div>
                      <div>
                        <span class="nk-text-muted">Date Issued: </span>
                        <span class="nk-text-muted font-weight-bold">{{ invoiceData.issue_date }}</span>
                      </div>
                    </column>
                  </row>
                  <row class="justify-content-between" v-else>
                    <column default="12" lg="4" xl="3">
                      <ion-skeleton-text animated style="width: 60%" class="mb-1"></ion-skeleton-text>
                      <ion-skeleton-text animated></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </column>
                    <column default="auto" lg="4" xl="3">
                      <ion-skeleton-text animated style="height: 30px;"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </column>
                  </row>
                  <div class="my-2">
                    <div v-if="invoiceData.period">
                      <span class="nk-text-muted">Period: </span>
                      <span class="nk-text-muted font-weight-bold">{{ invoiceData.period }}</span>
                    </div>
                    <ion-skeleton-text animated v-else style="width: 200px" class="mt-2"></ion-skeleton-text>
                  </div>
                  <table class="table" v-if="invoiceData.items.length">
                    <thead class="thead-light">
                    <tr class="active text-uppercase">
                      <th class="nk-text-muted">{{ $t('Definition') }}</th>
                      <th class="nk-text-muted">{{ $t('Amount') }}</th>
                      <th class="nk-text-muted">AANTAL</th>
                      <th class="nk-text-muted" style="width: 120px">{{ $t('Total') }}</th>
                    </tr>
                    </thead>
                    <tr v-for="(item,index) in invoiceData.items" :key="index">
                      <td>Lead #{{ item.lead_number }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.total_amount }}</td>
                    </tr>
                  </table>
                  <div v-else class="mt-4">
                    <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    <ion-skeleton-text animated></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                  </div>
                  <row class="justify-content-end">
                    <column default="auto" lg="4" xl="3" class="border-bottom my-5 pb-2">
                      <row>
                        <column default="6" class="nk-text-muted">Total: </column>
                        <column default="6" class="nk-text-muted font-weight-bold">
                          <span v-if="invoiceData.total_amount">&euro;{{ invoiceData.total_amount }}</span>
                          <ion-skeleton-text animated v-else style="height: 20px"></ion-skeleton-text>
                        </column>
                      </row>
                    </column>
                  </row>
                  <invoice-footer>

                  </invoice-footer>
                </card>
              </column>
              <column md="12" lg="3" xl="2" id="invoice_action_btns">
                <card>

                  <nk-button type="dark" full-width v-on:click="prepareInvoiceDownload" :disabled="!invoiceData.id">Download</nk-button>
                  <nk-button type="dark" outline full-width class="mb-lg-4" onclick="window.print()">Print</nk-button>

                  <router-link :to="{name: 'Invoices'}" class="btn btn-outline-dark mt-lg-5 d-inline d-lg-block text-center">
                    <nio-icon icon="ni-arrow-left" class="float-left"></nio-icon> {{ $t('All invoices')}}
                  </router-link>
                </card>
              </column>
            </row>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import {IonContent, IonPage, IonSkeletonText } from '@ionic/vue';
import {defineComponent, onMounted, reactive} from 'vue';

import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';

import {Card} from "@core/components/cards";
import InvoiceHead from "@/views/invoice/components/InvoiceHead";
import InvoiceFooter from "@/views/invoice/components/InvoiceFooter";

import { useRoute } from "vue-router"
import { useStore } from "vuex"
import axios from "@/libs/axios";
import invoiceFunctions from "../invoices/functions";



export default defineComponent({
  components: {
    InvoiceFooter,
    InvoiceHead,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSkeletonText,
  },
  setup() {

    const route = useRoute()
    let invoiceData = reactive({
      created_at: '',
      id: '',
      invoice_id: '',
      issue_date: '',
      items: [],
      period: '',
      status_text: '',
      total_amount: '',
    })
    const getInvoiceData = () => {
      axios.get('/api/advisor/invoice/'+route.params.id)
          .then( (resp) => {
            if(resp.data.error){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              for(let x in resp.data.data){
                invoiceData[x] = resp.data.data[x];
              }
            }
          })
          .catch( err => {
            console.log('Lead details ajax: '+err)
          })
    }
    onMounted(() => {
      getInvoiceData()
    })

    const store = useStore()
    const advisorData = store.state.advisor_data

    const { prepareInvoiceDownload } = invoiceFunctions()

    // invoice/{id}/{download_code}/download

    return {
      invoiceData,
      advisorData,
      prepareInvoiceDownload,
    }
  }
});
</script>
<style scoped>
@media print {
  .nk-sidebar, .nk-header, #invoice_action_btns{
    display: none;
  }
}
</style>
